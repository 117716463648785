$.fn.initHomepage = function () {

    // Scroll down element control
    var controller = new ScrollMagic.Controller(),
        viewportHeight = $(window).height();

    var fade = new TimelineMax().add([
        TweenMax.fromTo(
            ".scroll-down",
            1,
            { opacity: 1 },
            { opacity: 0, y:-30, ease: Linear.easeNone }
        )
    ]);

    new ScrollMagic.Scene({
        duration: viewportHeight*0.3,
        offset: 0,
        triggerElement: ".scroll-down"
    })
        .setTween(fade)
        .addTo(controller);

};