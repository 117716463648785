$.fn.initCarousel = function() {
    $('.slider').bxSlider({
        auto: true,
        speed: 550,
        easing: 'cubic-bezier(.475,.425,0,.995)',
        stopAutoOnClick: true,
        wrapperClass: 'carousel',
        prevText: '<div class="button-carousel-toggle prev"><span class="icon-arrow reverse hover"></span></div>',
        nextText: '<span class="button-carousel-toggle"><span class="icon-arrow hover"></span></span>',
        slideWidth: 700,
        slideHeight: 400

    });
}