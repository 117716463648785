$.fn.initCommon = function () {
    // Navigation bar control
    var header = $('header'),
        scrollPosition,
        viewportHeight = $('section.hero').height(),
        headerHeight = header.height();

    $(window).scroll(function () {
        scrollPosition = $(window).scrollTop();

        if (scrollPosition > 0) {
            header.addClass('show-background');
        } else if (scrollPosition <= 0) {
            header.removeClass('show-background');
        }
    });

    $(window).on("resize", function(e) {
        viewportHeight = $('section.hero').height();
    });

    // Hamburger control
    $('header .hamburger').click(function() {
        var menu = $(".mobile-menu");

        if (menu.hasClass('hidden')) {
            menu.removeClass('hidden');
            $(this).addClass('is-active');
            $('header').addClass('white');
            $('header').addClass('prevent-pointer');
            $('header').css('background-color', 'transparent');
            $('body').addClass('prevent-scroll');
        }else {
            menu.addClass('hidden');
            $(this).removeClass('is-active');
            $('header').removeClass('white');
            $('header').removeClass('prevent-pointer');
            $('header').css('background-color', '');
            $('body').removeClass('prevent-scroll');
        }
    })

    $('.mobile-menu').on('touchmove', function(e) {
        e.preventDefault();
        e.stopPropagation();

        return false;
    })
};