$.fn.initContact = function () {
    var controller = new ScrollMagic.Controller();

    var maps = [
            ".office.campbell",
            ".office.los-altos",
            ".office.mountain-view"
        ];

        console.log($(window).width());

    for (var i = 0; i < 3; i++) {
        (function() {
            var map = maps[i];

            new ScrollMagic.Scene({
                duration: 500,
                triggerElement: map
            }).on("enter", function (e) {
                clearMapClass();
                $(map).addClass("focus");
            }).on("leave", function (e) {
                clearMapClass();
                $(map).addClass("focus");
            }).addTo(controller);
        }.bind(clearMapClass))();
    }

    function clearMapClass() {
        $.each(maps, function(index) {
            $(maps[index]).removeClass("focus");
        });
    }
};